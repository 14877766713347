*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.loading-bar{
    position: absolute;
    width: 100%;
    height: 2px;
    background: #ffffff;
    top: 50%;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.ended{
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}

